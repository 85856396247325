<template>
  <svg
    width="61"
    height="100"
    viewBox="0 0 61 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V60.4147H60.4134V0H0Z"
      fill="#FF1628"
    />
    <path
      d="M14.6208 8.45106V9.30354C14.6208 10.8816 14.1159 12.1594 13.1062 13.137C12.0969 14.1155 10.7874 14.6047 9.19364 14.6047C7.48869 14.6047 6.06923 14.0525 4.94868 12.948C3.88007 11.8605 3.28007 10.3976 3.27732 8.87294C3.27456 7.34827 3.86927 5.88321 4.93393 4.79184C6.05315 3.67129 7.42569 3.11905 9.06765 3.11905C11.1345 3.11905 12.9319 4.12835 13.8796 5.69123L12.0178 6.76353C11.4964 5.86414 10.3611 5.23282 9.05157 5.23282C7.99356 5.23282 7.12589 5.57998 6.44855 6.27429C5.77032 6.95252 5.43791 7.82109 5.43791 8.8773C5.43791 9.93352 5.78507 10.786 6.4633 11.4656C7.14153 12.1451 8.07175 12.4749 9.20973 12.4749C10.8973 12.4749 12.0326 11.6854 12.4266 10.3758H9.07569V8.45106H14.6208Z"
      fill="white"
    />
    <path
      d="M17.8752 7.85326C18.2358 6.84396 19.1352 6.33863 20.1539 6.33863V8.61057C19.5952 8.52378 19.0247 8.66001 18.5655 8.9899C18.1004 9.32097 17.8752 9.85712 17.8752 10.6144V14.3849H15.9478V6.49546H17.8752V7.85326Z"
      fill="white"
    />
    <path
      d="M23.4244 11.2752C23.6925 12.2698 24.4337 12.759 25.6494 12.759C26.4215 12.759 27.022 12.491 27.416 11.9695L29.0567 12.9159C28.2833 14.0364 27.1319 14.6047 25.6173 14.6047C24.3077 14.6047 23.2662 14.2107 22.4781 13.4212C21.69 12.6317 21.2945 11.6385 21.2945 10.4402C21.2945 9.24189 21.6886 8.26207 22.462 7.47393C23.2354 6.68579 24.2447 6.27429 25.4591 6.27429C26.6118 6.27429 27.5742 6.66836 28.3154 7.47393C29.0567 8.2795 29.4507 9.25663 29.4507 10.4402C29.4469 10.7202 29.4205 10.9995 29.3717 11.2752H23.4244ZM27.416 9.69761C27.1788 8.62531 26.3907 8.10391 25.4591 8.10391C24.3707 8.10391 23.6295 8.68831 23.3922 9.69761H27.416Z"
      fill="white"
    />
    <path
      d="M36.7879 6.49546H38.824V14.3836H36.7879V13.4533C36.1727 14.2254 35.3216 14.6047 34.2171 14.6047C33.7052 14.6104 33.1981 14.5062 32.7299 14.2993C32.2617 14.0923 31.8432 13.7874 31.5028 13.4051C30.7808 12.5888 30.3822 11.5367 30.3822 10.4469C30.3822 9.35709 30.7808 8.30493 31.5028 7.48867C32.2601 6.68445 33.1595 6.27429 34.2171 6.27429C35.3216 6.27429 36.1727 6.65362 36.7879 7.42568V6.49546ZM34.5951 12.6612C34.8836 12.6706 35.1709 12.6209 35.4395 12.5151C35.708 12.4093 35.9521 12.2496 36.1566 12.0459C36.3662 11.8352 36.5303 11.5838 36.6389 11.3071C36.7475 11.0305 36.7982 10.7345 36.7879 10.4375C36.7998 10.1425 36.7498 9.84827 36.6411 9.57378C36.5323 9.29929 36.3673 9.05062 36.1566 8.84379C35.7377 8.43875 35.1778 8.21232 34.5951 8.21232C34.0124 8.21232 33.4525 8.43875 33.0335 8.84379C32.8283 9.05381 32.6678 9.30341 32.5621 9.57739C32.4563 9.85137 32.4074 10.144 32.4183 10.4375C32.4089 10.733 32.4585 11.0275 32.5641 11.3036C32.6697 11.5798 32.8293 11.8321 33.0335 12.0459C33.2379 12.2501 33.4818 12.4103 33.7504 12.5165C34.0189 12.6228 34.3064 12.6729 34.5951 12.6639V12.6612Z"
      fill="white"
    />
    <path
      d="M45.4977 8.45106H43.715V11.7403C43.715 12.5915 44.3302 12.6076 45.4977 12.5446V14.3903C42.7057 14.7066 41.6803 13.901 41.6803 11.7403V8.45106H40.3078V6.49546H41.6803V4.90443L43.715 4.2892V6.49814H45.4977V8.45106Z"
      fill="white"
    />
    <path
      d="M8.84651 17.2104C9.34238 17.2005 9.83519 17.2904 10.2957 17.4746C10.7562 17.6588 11.175 17.9336 11.5273 18.2827C12.2242 18.9824 12.6155 19.9297 12.6155 20.9172C12.6155 21.9048 12.2242 22.8521 11.5273 23.5517C11.175 23.9009 10.7562 24.1757 10.2957 24.3599C9.83519 24.5441 9.34238 24.6339 8.84651 24.624H6.90565V28.2524H4.72888V17.2077L8.84651 17.2104ZM8.84651 22.5907C9.77673 22.5907 10.455 21.8655 10.455 20.9192C10.455 19.9729 9.77673 19.2464 8.84651 19.2464H6.90565V22.5974L8.84651 22.5907Z"
      fill="white"
    />
    <path
      d="M14.1008 28.2551V17.3136H16.1328V28.2551H14.1008Z"
      fill="white"
    />
    <path
      d="M24.309 20.367H26.3437V28.2551H24.309V27.3235C23.6938 28.0969 22.8413 28.4763 21.7368 28.4763C21.225 28.4815 20.718 28.3771 20.2499 28.1702C19.7817 27.9633 19.3633 27.6586 19.0226 27.2766C18.3013 26.46 17.9033 25.408 17.9033 24.3184C17.9033 23.2289 18.3013 22.1769 19.0226 21.3602C19.7812 20.556 20.6793 20.1458 21.7368 20.1458C22.8413 20.1458 23.6938 20.5238 24.309 21.2972V20.367ZM22.1162 26.5327C22.4046 26.542 22.692 26.4922 22.9605 26.3864C23.229 26.2806 23.4731 26.121 23.6777 25.9175C23.8872 25.7067 24.0513 25.4553 24.1599 25.1786C24.2685 24.902 24.3192 24.606 24.309 24.309C24.3208 24.0141 24.2707 23.7199 24.1619 23.4454C24.0532 23.1709 23.8883 22.9223 23.6777 22.7153C23.2584 22.3105 22.6983 22.0843 22.1155 22.0843C21.5327 22.0843 20.9726 22.3105 20.5533 22.7153C20.3481 22.9254 20.1877 23.175 20.0819 23.449C19.9762 23.7229 19.9272 24.0156 19.9381 24.309C19.9287 24.6046 19.9783 24.899 20.084 25.1751C20.1896 25.4513 20.3491 25.7036 20.5533 25.9175C20.7579 26.1217 21.002 26.2818 21.2708 26.3881C21.5396 26.4944 21.8273 26.5445 22.1162 26.5354V26.5327Z"
      fill="white"
    />
    <path
      d="M29.2791 27.2766C28.5194 26.4759 28.0958 25.4142 28.0958 24.3104C28.0958 23.2066 28.5194 22.1448 29.2791 21.3441C30.0834 20.5399 31.0779 20.1458 32.2615 20.1458C33.7908 20.1458 35.1486 20.934 35.7947 22.1805L34.0442 23.2059C33.7278 22.5599 33.0496 22.1497 32.2454 22.1497C31.031 22.1497 30.1316 23.0477 30.1316 24.3104C30.1246 24.595 30.1739 24.8781 30.2767 25.1436C30.3796 25.4091 30.5339 25.6516 30.7308 25.8572C30.9292 26.0549 31.1658 26.21 31.4263 26.313C31.6867 26.416 31.9654 26.4648 32.2454 26.4563C33.0657 26.4563 33.7439 26.0622 34.0603 25.4148L35.8269 26.4242C35.4702 27.0536 34.9513 27.576 34.3243 27.9369C33.6972 28.2978 32.9849 28.484 32.2615 28.4763C31.0779 28.4763 30.0834 28.0809 29.2791 27.2766Z"
      fill="white"
    />
    <path
      d="M39.0773 25.1441C39.3454 26.1373 40.0879 26.6265 41.3023 26.6265C42.0757 26.6265 42.6748 26.3585 43.0689 25.8384L44.7095 26.7847C43.9375 27.9053 42.7848 28.4736 41.2701 28.4736C39.9606 28.4736 38.9205 28.0782 38.131 27.29C37.3415 26.5019 36.9474 25.5073 36.9474 24.3077C36.9474 23.1081 37.3415 22.1309 38.1149 21.3414C38.8883 20.552 39.8976 20.1432 41.1133 20.1432C42.2647 20.1432 43.2271 20.5372 43.9683 21.3414C44.7095 22.1457 45.1049 23.1241 45.1049 24.3077C45.1004 24.5881 45.0739 24.8678 45.0259 25.1441H39.0773ZM43.0743 23.5692C42.8384 22.4969 42.0489 21.9754 41.1187 21.9754C40.0289 21.9754 39.2877 22.5599 39.0518 23.5692H43.0743Z"
      fill="white"
    />
    <path
      d="M11.4026 31.6342V33.6126H8.42026V42.1253H6.24349V33.6126H3.27724V31.6342H11.4026Z"
      fill="white"
    />
    <path
      d="M14.7924 42.3465C13.8413 42.3324 12.9244 41.9898 12.1973 41.3767C11.4701 40.7636 10.9774 39.9178 10.8029 38.9828C10.6283 38.0478 10.7826 37.0813 11.2395 36.2471C11.6965 35.4129 12.428 34.7625 13.3099 34.4062C14.1918 34.05 15.1697 34.0098 16.0779 34.2925C16.986 34.5752 17.7684 35.1634 18.2923 35.9573C18.8161 36.7512 19.0492 37.7018 18.9519 38.648C18.8546 39.5941 18.433 40.4775 17.7586 41.1482C17.372 41.5396 16.9094 41.8478 16.3993 42.0539C15.8892 42.26 15.3424 42.3595 14.7924 42.3465ZM14.7924 40.3587C15.0761 40.3666 15.3583 40.316 15.6217 40.2102C15.885 40.1044 16.1237 39.9455 16.3231 39.7435C16.7185 39.3194 16.9384 38.7611 16.9384 38.1813C16.9384 37.6014 16.7185 37.0431 16.3231 36.619C15.9113 36.2242 15.3629 36.0037 14.7924 36.0037C14.2219 36.0037 13.6734 36.2242 13.2617 36.619C12.8759 37.048 12.6625 37.6044 12.6625 38.1813C12.6625 38.7581 12.8759 39.3145 13.2617 39.7435C13.461 39.9455 13.6997 40.1044 13.9631 40.2102C14.2264 40.316 14.5087 40.3666 14.7924 40.3587Z"
      fill="white"
    />
    <path
      d="M6.66701 56.6429L3.57477 45.5982H5.86279L7.97656 53.7866L10.2807 45.5982H12.1424L14.4613 53.7866L16.5751 45.5982H18.8631L15.7708 56.6429H13.2938L11.2109 49.3539L9.14402 56.6429H6.66701Z"
      fill="white"
    />
    <path
      d="M22.8346 56.8627C21.8836 56.8487 20.9667 56.5061 20.2395 55.893C19.5124 55.2799 19.0197 54.4341 18.8451 53.4991C18.6706 52.5641 18.8248 51.5975 19.2818 50.7634C19.7387 49.9292 20.4702 49.2788 21.3521 48.9225C22.234 48.5663 23.212 48.5261 24.1201 48.8088C25.0283 49.0915 25.8107 49.6797 26.3345 50.4736C26.8584 51.2674 27.0914 52.2181 26.9942 53.1642C26.8969 54.1104 26.4753 54.9938 25.8009 55.6644C25.4143 56.0559 24.9517 56.3641 24.4416 56.5702C23.9315 56.7762 23.3846 56.8758 22.8346 56.8627ZM22.8346 54.875C23.1183 54.8828 23.4006 54.8323 23.6639 54.7265C23.9272 54.6206 24.166 54.4617 24.3653 54.2597C24.7608 53.8356 24.9807 53.2774 24.9807 52.6975C24.9807 52.1177 24.7608 51.5594 24.3653 51.1353C23.9535 50.7404 23.4051 50.52 22.8346 50.52C22.2641 50.52 21.7157 50.7404 21.3039 51.1353C20.9182 51.5642 20.7048 52.1207 20.7048 52.6975C20.7048 53.2744 20.9182 53.8308 21.3039 54.2597C21.5032 54.4617 21.742 54.6206 22.0053 54.7265C22.2686 54.8323 22.5509 54.8828 22.8346 54.875Z"
      fill="white"
    />
    <path
      d="M30.4922 50.1099C30.8769 49.1006 31.8151 48.6007 32.8874 48.6007V50.8726C32.302 50.7864 31.7056 50.9216 31.2146 51.2519C30.7254 51.583 30.4881 52.1191 30.4881 52.8765V56.6429H28.4642V48.7535H30.4989L30.4922 50.1099Z"
      fill="white"
    />
    <path
      d="M41.6629 56.6429H39.2971L36.4247 53.0614V56.6429H34.39V45.5982H36.4247V52.2197L39.139 48.7481H41.5691L38.3978 52.6446L41.6629 56.6429Z"
      fill="white"
    />
    <path
      d="M42.4041 55.2208C42.4009 54.8673 42.5028 54.5208 42.6969 54.2253C42.891 53.9298 43.1685 53.6986 43.4942 53.5611C43.8199 53.4236 44.1791 53.386 44.5262 53.453C44.8733 53.52 45.1927 53.6886 45.4438 53.9375C45.695 54.1863 45.8665 54.5042 45.9366 54.8507C46.0068 55.1972 45.9724 55.5567 45.8378 55.8836C45.7033 56.2106 45.4747 56.4902 45.1809 56.6869C44.8872 56.8837 44.5417 56.9887 44.1881 56.9887C43.9542 56.9923 43.7219 56.9491 43.5049 56.8618C43.2879 56.7744 43.0905 56.6445 42.9244 56.4799C42.7582 56.3152 42.6266 56.119 42.5373 55.9028C42.4479 55.6866 42.4026 55.4547 42.4041 55.2208ZM45.6505 55.2127C45.6505 54.3361 45.0406 53.7262 44.1881 53.7262C43.3356 53.7262 42.7258 54.3442 42.7258 55.2208C42.7268 55.6086 42.8819 55.9801 43.1569 56.2536C43.2931 56.389 43.4546 56.4963 43.6322 56.5693C43.8098 56.6423 44.0001 56.6796 44.1922 56.6791C44.3842 56.6786 44.5742 56.6402 44.7515 56.5662C44.9287 56.4923 45.0896 56.3841 45.225 56.2479C45.3604 56.1118 45.4677 55.9503 45.5407 55.7727C45.6137 55.595 45.651 55.4048 45.6505 55.2127ZM43.5783 54.312H44.1962C44.6063 54.312 44.8664 54.4809 44.8664 54.8481V54.8562C44.8713 54.9652 44.8377 55.0724 44.7715 55.1591C44.7054 55.2459 44.6108 55.3066 44.5045 55.3307L44.9709 56.0773H44.5688L44.1466 55.3897H43.9616V56.0719H43.5769L43.5783 54.312ZM44.1881 55.1243C44.3892 55.1243 44.4937 55.0358 44.4937 54.8669V54.8589C44.4937 54.6739 44.3811 54.6096 44.1881 54.6096H43.9629V55.1243H44.1881Z"
      fill="white"
    />
    <path
      d="M60.4134 80.2121L30.2067 100L0 80.2121V60.4147H60.4134V80.2121Z"
      fill="#002171"
    />
    <path
      d="M13.9595 67.6608H12.9033C12.8408 67.357 12.7315 67.0901 12.5753 66.8601C12.422 66.6301 12.2346 66.437 12.0131 66.2808C11.7945 66.1218 11.5517 66.0026 11.2848 65.9231C11.0179 65.8436 10.7396 65.8038 10.45 65.8038C9.92188 65.8038 9.44343 65.9373 9.01468 66.2042C8.58877 66.4711 8.24946 66.8643 7.99675 67.3839C7.74689 67.9036 7.62195 68.541 7.62195 69.2963C7.62195 70.0516 7.74689 70.689 7.99675 71.2086C8.24946 71.7282 8.58877 72.1215 9.01468 72.3884C9.44343 72.6553 9.92188 72.7888 10.45 72.7888C10.7396 72.7888 11.0179 72.749 11.2848 72.6695C11.5517 72.59 11.7945 72.4722 12.0131 72.316C12.2346 72.157 12.422 71.9625 12.5753 71.7325C12.7315 71.4997 12.8408 71.2328 12.9033 70.9318H13.9595C13.88 71.3776 13.7352 71.7765 13.5251 72.1286C13.315 72.4807 13.0537 72.7803 12.7414 73.0273C12.4291 73.2715 12.0784 73.4575 11.6894 73.5852C11.3032 73.713 10.8901 73.7769 10.45 73.7769C9.70608 73.7769 9.0445 73.5952 8.46526 73.2317C7.88602 72.8683 7.43029 72.3515 7.09808 71.6814C6.76587 71.0113 6.59976 70.2163 6.59976 69.2963C6.59976 68.3763 6.76587 67.5813 7.09808 66.9112C7.43029 66.2411 7.88602 65.7243 8.46526 65.3609C9.0445 64.9974 9.70608 64.8157 10.45 64.8157C10.8901 64.8157 11.3032 64.8796 11.6894 65.0074C12.0784 65.1351 12.4291 65.3225 12.7414 65.5696C13.0537 65.8138 13.315 66.1119 13.5251 66.464C13.7352 66.8132 13.88 67.2122 13.9595 67.6608Z"
      fill="white"
    />
    <path
      d="M18.2729 73.7939C17.6426 73.7939 17.0988 73.6548 16.6417 73.3765C16.1874 73.0954 15.8367 72.7036 15.5897 72.201C15.3455 71.6956 15.2234 71.1078 15.2234 70.4377C15.2234 69.7676 15.3455 69.177 15.5897 68.6659C15.8367 68.152 16.1803 67.7516 16.6204 67.4649C17.0634 67.1752 17.5801 67.0304 18.1707 67.0304C18.5114 67.0304 18.8479 67.0872 19.1801 67.2008C19.5123 67.3144 19.8147 67.4989 20.0873 67.7545C20.3599 68.0072 20.5771 68.3422 20.739 68.7596C20.9008 69.177 20.9817 69.691 20.9817 70.3014V70.7274H15.9389V69.8585H19.9596C19.9596 69.4894 19.8857 69.16 19.7381 68.8704C19.5933 68.5808 19.386 68.3522 19.1162 68.1847C18.8493 68.0171 18.5342 67.9334 18.1707 67.9334C17.7704 67.9334 17.424 68.0328 17.1315 68.2315C16.8419 68.4274 16.619 68.683 16.4628 68.9982C16.3066 69.3133 16.2286 69.6512 16.2286 70.0118V70.5911C16.2286 71.0851 16.3137 71.5039 16.4841 71.8475C16.6573 72.1882 16.8972 72.448 17.2039 72.6269C17.5106 72.803 17.8669 72.891 18.2729 72.891C18.537 72.891 18.7755 72.8541 18.9885 72.7803C19.2043 72.7036 19.3902 72.59 19.5464 72.4395C19.7026 72.2862 19.8233 72.0959 19.9084 71.8688L20.8795 72.1414C20.7773 72.4708 20.6055 72.7604 20.3642 73.0102C20.1228 73.2573 19.8247 73.4503 19.4698 73.5895C19.1148 73.7258 18.7159 73.7939 18.2729 73.7939Z"
      fill="white"
    />
    <path
      d="M22.5108 73.6576V67.1156H23.4818V68.1037H23.55C23.6692 67.78 23.885 67.5174 24.1974 67.3158C24.5097 67.1142 24.8618 67.0134 25.2536 67.0134C25.3275 67.0134 25.4197 67.0148 25.5305 67.0177C25.6412 67.0205 25.725 67.0248 25.7818 67.0304V68.0526C25.7477 68.0441 25.6696 68.0313 25.5475 68.0143C25.4283 67.9944 25.3019 67.9845 25.1685 67.9845C24.8504 67.9845 24.5665 68.0512 24.3166 68.1847C24.0696 68.3153 23.8737 68.497 23.7289 68.7298C23.5869 68.9598 23.5159 69.2225 23.5159 69.5178V73.6576H22.5108Z"
      fill="white"
    />
    <path
      d="M30.2283 67.1156V67.9674H26.838V67.1156H30.2283ZM27.8261 65.5483H28.8313V71.7836C28.8313 72.0676 28.8725 72.2805 28.9548 72.4225C29.04 72.5616 29.1479 72.6553 29.2785 72.7036C29.4119 72.749 29.5525 72.7717 29.7001 72.7717C29.8109 72.7717 29.9017 72.7661 29.9727 72.7547C30.0437 72.7405 30.1005 72.7291 30.1431 72.7206L30.3475 73.6236C30.2794 73.6491 30.1843 73.6747 30.0622 73.7002C29.9401 73.7286 29.7853 73.7428 29.5979 73.7428C29.314 73.7428 29.0357 73.6818 28.7631 73.5597C28.4934 73.4376 28.2691 73.2516 28.0902 73.0017C27.9142 72.7519 27.8261 72.4367 27.8261 72.0562V65.5483Z"
      fill="white"
    />
    <path
      d="M31.7403 73.6576V67.1156H32.7454V73.6576H31.7403ZM32.2514 66.0253C32.0554 66.0253 31.8865 65.9586 31.7445 65.8251C31.6054 65.6917 31.5358 65.5312 31.5358 65.3438C31.5358 65.1564 31.6054 64.996 31.7445 64.8625C31.8865 64.7291 32.0554 64.6624 32.2514 64.6624C32.4473 64.6624 32.6148 64.7291 32.7539 64.8625C32.8959 64.996 32.9669 65.1564 32.9669 65.3438C32.9669 65.5312 32.8959 65.6917 32.7539 65.8251C32.6148 65.9586 32.4473 66.0253 32.2514 66.0253Z"
      fill="white"
    />
    <path
      d="M37.4997 67.1156V67.9674H33.9731V67.1156H37.4997ZM35.0294 73.6576V66.2127C35.0294 65.8379 35.1174 65.5256 35.2934 65.2757C35.4695 65.0258 35.6981 64.8384 35.9792 64.7135C36.2603 64.5885 36.557 64.5261 36.8693 64.5261C37.1163 64.5261 37.3179 64.546 37.4741 64.5857C37.6303 64.6255 37.7467 64.6624 37.8234 64.6964L37.5337 65.5653C37.4826 65.5483 37.4116 65.527 37.3208 65.5014C37.2328 65.4759 37.1163 65.4631 36.9715 65.4631C36.6393 65.4631 36.3994 65.5468 36.2517 65.7144C36.1069 65.8819 36.0345 66.1275 36.0345 66.4512V73.6576H35.0294Z"
      fill="white"
    />
    <path
      d="M38.9084 73.6576V67.1156H39.9135V73.6576H38.9084ZM39.4195 66.0253C39.2235 66.0253 39.0546 65.9586 38.9126 65.8251C38.7735 65.6917 38.7039 65.5312 38.7039 65.3438C38.7039 65.1564 38.7735 64.996 38.9126 64.8625C39.0546 64.7291 39.2235 64.6624 39.4195 64.6624C39.6154 64.6624 39.7829 64.7291 39.922 64.8625C40.064 64.996 40.135 65.1564 40.135 65.3438C40.135 65.5312 40.064 65.6917 39.922 65.8251C39.7829 65.9586 39.6154 66.0253 39.4195 66.0253Z"
      fill="white"
    />
    <path
      d="M44.4974 73.7939C43.867 73.7939 43.3233 73.6548 42.8661 73.3765C42.4118 73.0954 42.0612 72.7036 41.8141 72.201C41.57 71.6956 41.4479 71.1078 41.4479 70.4377C41.4479 69.7676 41.57 69.177 41.8141 68.6659C42.0612 68.152 42.4047 67.7516 42.8448 67.4649C43.2878 67.1752 43.8046 67.0304 44.3952 67.0304C44.7359 67.0304 45.0724 67.0872 45.4046 67.2008C45.7368 67.3144 46.0392 67.4989 46.3118 67.7545C46.5844 68.0072 46.8016 68.3422 46.9634 68.7596C47.1253 69.177 47.2062 69.691 47.2062 70.3014V70.7274H42.1634V69.8585H46.184C46.184 69.4894 46.1102 69.16 45.9625 68.8704C45.8177 68.5808 45.6104 68.3522 45.3407 68.1847C45.0738 68.0171 44.7586 67.9334 44.3952 67.9334C43.9948 67.9334 43.6484 68.0328 43.3559 68.2315C43.0663 68.4274 42.8434 68.683 42.6873 68.9982C42.5311 69.3133 42.453 69.6512 42.453 70.0118V70.5911C42.453 71.0851 42.5382 71.5039 42.7086 71.8475C42.8818 72.1882 43.1217 72.448 43.4283 72.6269C43.735 72.803 44.0914 72.891 44.4974 72.891C44.7615 72.891 45 72.8541 45.2129 72.7803C45.4287 72.7036 45.6147 72.59 45.7709 72.4395C45.927 72.2862 46.0477 72.0959 46.1329 71.8688L47.104 72.1414C47.0018 72.4708 46.83 72.7604 46.5886 73.0102C46.3473 73.2573 46.0491 73.4503 45.6942 73.5895C45.3393 73.7258 44.9403 73.7939 44.4974 73.7939Z"
      fill="white"
    />
    <path
      d="M51.2055 73.7939C50.6603 73.7939 50.1791 73.6562 49.7617 73.3808C49.3443 73.1025 49.0177 72.7107 48.7821 72.2053C48.5464 71.697 48.4286 71.0965 48.4286 70.4037C48.4286 69.7165 48.5464 69.1202 48.7821 68.6148C49.0177 68.1094 49.3457 67.719 49.7659 67.4436C50.1861 67.1682 50.6717 67.0304 51.2225 67.0304C51.6484 67.0304 51.9849 67.1014 52.2319 67.2434C52.4818 67.3825 52.6721 67.5415 52.8027 67.7204C52.9361 67.8965 53.0398 68.0413 53.1136 68.1548H53.1988V64.935H54.2039V73.6576H53.2328V72.6525H53.1136C53.0398 72.7717 52.9347 72.9222 52.7984 73.1039C52.6621 73.2828 52.4676 73.4433 52.2149 73.5852C51.9622 73.7244 51.6257 73.7939 51.2055 73.7939ZM51.3418 72.891C51.745 72.891 52.0857 72.7859 52.364 72.5758C52.6422 72.3629 52.8538 72.069 52.9986 71.6942C53.1434 71.3165 53.2158 70.8807 53.2158 70.3866C53.2158 69.8982 53.1448 69.4709 53.0028 69.1046C52.8609 68.7355 52.6508 68.4487 52.3725 68.2443C52.0942 68.037 51.7507 67.9334 51.3418 67.9334C50.9159 67.9334 50.561 68.0427 50.277 68.2613C49.9959 68.4771 49.7844 68.771 49.6424 69.143C49.5033 69.5121 49.4337 69.9266 49.4337 70.3866C49.4337 70.8523 49.5047 71.2754 49.6467 71.6558C49.7915 72.0335 50.0044 72.3345 50.2855 72.5588C50.5695 72.7803 50.9216 72.891 51.3418 72.891Z"
      fill="white"
    />
    <path
      d="M20.5975 82.8311V82.2303L22.8541 79.7601C23.1189 79.4708 23.337 79.2193 23.5083 79.0057C23.6797 78.7898 23.8065 78.5873 23.8889 78.3982C23.9734 78.2068 24.0157 78.0065 24.0157 77.7973C24.0157 77.557 23.9579 77.3489 23.8421 77.1731C23.7286 76.9973 23.5729 76.8615 23.3748 76.7658C23.1767 76.6702 22.9542 76.6223 22.7072 76.6223C22.4446 76.6223 22.2154 76.6768 22.0195 76.7859C21.8259 76.8927 21.6757 77.0429 21.5689 77.2365C21.4643 77.4301 21.412 77.6571 21.412 77.9175H20.6242C20.6242 77.5169 20.7166 77.1653 20.9013 76.8626C21.086 76.56 21.3375 76.3241 21.6557 76.155C21.9761 75.9858 22.3355 75.9013 22.7339 75.9013C23.1345 75.9013 23.4894 75.9858 23.7987 76.155C24.1081 76.3241 24.3506 76.5522 24.5264 76.8393C24.7022 77.1264 24.7901 77.4457 24.7901 77.7973C24.7901 78.0488 24.7445 78.2947 24.6533 78.535C24.5643 78.7731 24.4085 79.0391 24.186 79.3328C23.9656 79.6244 23.6597 79.9804 23.268 80.401L21.7325 82.0434V82.0968H24.9103V82.8311H20.5975Z"
      fill="white"
    />
    <path
      d="M28.5188 82.9246C28.0159 82.9246 27.5875 82.7878 27.2336 82.514C26.8798 82.2381 26.6094 81.8386 26.4225 81.3157C26.2355 80.7905 26.1421 80.1562 26.1421 79.4129C26.1421 78.6741 26.2355 78.0432 26.4225 77.5203C26.6116 76.9951 26.8831 76.5945 27.237 76.3185C27.593 76.0404 28.0203 75.9013 28.5188 75.9013C29.0173 75.9013 29.4434 76.0404 29.7973 76.3185C30.1533 76.5945 30.4248 76.9951 30.6118 77.5203C30.8009 78.0432 30.8955 78.6741 30.8955 79.4129C30.8955 80.1562 30.802 80.7905 30.6151 81.3157C30.4282 81.8386 30.1578 82.2381 29.804 82.514C29.4501 82.7878 29.0217 82.9246 28.5188 82.9246ZM28.5188 82.1902C29.0173 82.1902 29.4045 81.9499 29.6804 81.4692C29.9564 80.9885 30.0944 80.3031 30.0944 79.4129C30.0944 78.821 30.0309 78.3169 29.9041 77.9008C29.7795 77.4846 29.5992 77.1675 29.3633 76.9494C29.1297 76.7314 28.8481 76.6223 28.5188 76.6223C28.0248 76.6223 27.6386 76.866 27.3605 77.3533C27.0823 77.8385 26.9432 78.525 26.9432 79.4129C26.9432 80.0049 27.0055 80.5078 27.1301 80.9218C27.2548 81.3357 27.4339 81.6506 27.6676 81.8664C27.9035 82.0823 28.1872 82.1902 28.5188 82.1902Z"
      fill="white"
    />
    <path
      d="M32.164 82.8311V82.2303L34.4205 79.7601C34.6853 79.4708 34.9034 79.2193 35.0748 79.0057C35.2461 78.7898 35.373 78.5873 35.4553 78.3982C35.5399 78.2068 35.5822 78.0065 35.5822 77.7973C35.5822 77.557 35.5243 77.3489 35.4086 77.1731C35.2951 76.9973 35.1393 76.8615 34.9413 76.7658C34.7432 76.6702 34.5207 76.6223 34.2736 76.6223C34.0111 76.6223 33.7818 76.6768 33.586 76.7859C33.3924 76.8927 33.2422 77.0429 33.1354 77.2365C33.0308 77.4301 32.9785 77.6571 32.9785 77.9175H32.1907C32.1907 77.5169 32.283 77.1653 32.4677 76.8626C32.6525 76.56 32.9039 76.3241 33.2222 76.155C33.5426 75.9858 33.902 75.9013 34.3004 75.9013C34.7009 75.9013 35.0559 75.9858 35.3652 76.155C35.6745 76.3241 35.9171 76.5522 36.0929 76.8393C36.2687 77.1264 36.3566 77.4457 36.3566 77.7973C36.3566 78.0488 36.311 78.2947 36.2198 78.535C36.1307 78.7731 35.975 79.0391 35.7524 79.3328C35.5321 79.6244 35.2261 79.9804 34.8344 80.401L33.2989 82.0434V82.0968H36.4768V82.8311H32.164Z"
      fill="white"
    />
    <path
      d="M37.8554 82.8311V82.2303L40.112 79.7601C40.3768 79.4708 40.5949 79.2193 40.7662 79.0057C40.9376 78.7898 41.0644 78.5873 41.1468 78.3982C41.2313 78.2068 41.2736 78.0065 41.2736 77.7973C41.2736 77.557 41.2158 77.3489 41.1 77.1731C40.9865 76.9973 40.8308 76.8615 40.6327 76.7658C40.4346 76.6702 40.2121 76.6223 39.9651 76.6223C39.7025 76.6223 39.4733 76.6768 39.2774 76.7859C39.0838 76.8927 38.9336 77.0429 38.8268 77.2365C38.7222 77.4301 38.6699 77.6571 38.6699 77.9175H37.8821C37.8821 77.5169 37.9745 77.1653 38.1592 76.8626C38.3439 76.56 38.5954 76.3241 38.9136 76.155C39.234 75.9858 39.5934 75.9013 39.9918 75.9013C40.3924 75.9013 40.7473 75.9858 41.0566 76.155C41.366 76.3241 41.6085 76.5522 41.7843 76.8393C41.9601 77.1264 42.048 77.4457 42.048 77.7973C42.048 78.0488 42.0024 78.2947 41.9112 78.535C41.8222 78.7731 41.6664 79.0391 41.4439 79.3328C41.2235 79.6244 40.9175 79.9804 40.5259 80.401L38.9904 82.0434V82.0968H42.1682V82.8311H37.8554Z"
      fill="white"
    />
    <path
      d="M30.7672 86.211V90.9096H30.2166L27.6562 87.2204H27.6103V90.9096H27.0414V86.211H27.592L30.1616 89.9093H30.2074V86.211H30.7672Z"
      fill="white"
    />
    <path
      d="M31.9058 90.9096V86.211H32.4747V90.4049H34.6589V90.9096H31.9058Z"
      fill="white"
    />
    <path
      d="M57.352 86.3577H56.6657V88.3186H56.1845V86.3577H55.4861V85.9113H57.352V86.3577ZM60.1198 85.9113V88.324H59.6386V86.7182L58.9041 87.8857H58.8559L58.1213 86.7182V88.3186H57.6401V85.906H58.1213L58.88 87.1123L59.6386 85.906L60.1198 85.9113Z"
      fill="#002171"
    />
  </svg>
</template>
