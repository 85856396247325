<template>
  <section>
    <BaseChipTitle
      title="Altijd werk bij jou"
      chip="in de regio"
      center
    />
    <BaseCarousel
      :items="locations"
      :config="swiperConfig"
      type="location"
      class="container-extend"
    >
      <template #item="{slug, title, active_image}">
        <NuxtLink :to="`/${slug}`">
          <div class="card-base block h-full">
            <NuxtPicture
              v-if="active_image"
              :img-attrs="{class: 'card-img aspect-1 w-full rounded-2xl lg:max-h-80'}"
              :src="active_image.url"
              :alt="title"
              sizes="md:700px 1400px"
            />
            <div class="flex items-center justify-between px-6 py-8 lg:py-5">
              <div class="text-violet lg:text-xl lg:leading-9">
                {{ title }}
              </div>
              <IconsControlNext class="lg:scale-125" />
            </div>
          </div>
        </NuxtLink>
      </template>
    </BaseCarousel>
  </section>
</template>

<script setup lang="ts">
import locationQuery from '~/graphql/queries/location.gql';

const swiperConfig = {
  slidesPerView: 1.2,
  spaceBetween: 8,
  breakpoints: {
    786: {
      slidesPerView: 3.5,
      spaceBetween: 24,
    },
    1440: {
      slidesPerView: 4.5,
      spaceBetween: 24,
    },
  },
};

const {result: locationResult} = useQuery(locationQuery);
const locations = computed<Plaats[]>(() => locationResult.value?.location ?? []);
</script>

<style>
.markers li {
  background-image: url("~/assets/img/check-square.svg");
  padding-left: 3em;
  background-repeat: no-repeat;
  line-height: 40px;
  margin: 1em 0;
}
</style>
