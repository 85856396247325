<template>
  <div>
    <BaseSectionTile
      v-if="page?.template?.__typename === 'TemplateHome'"
      fg-class="bg-white overflow-hidden"
      bg-class="bg-cream lg:p-6"
    >
      <header class="relative rounded-t-2xl bg-violet bg-marble-pattern bg-center bg-no-repeat pt-10 lg:rounded-2xl lg:bg-right-top lg:pb-32 lg:pt-0">
        <div class="container space-y-12 lg:flex lg:space-y-0">
          <div class="lg:w-5/12 lg:translate-y-[234px] lg:pr-20">
            <h1 class="max-w-sm text-4xl font-bold !leading-tight text-white lg:text-6xl">
              {{ page.title }}
            </h1>
            <SofieText
              class="markers mt-4 text-lg text-white"
              :value="page.template.text"
            />
          </div>
          <div class="z-10 lg:w-4/12 lg:translate-y-[234px]">
            <FindWork />
          </div>

          <div class="relative hidden translate-y-[136px] max-xl:hidden lg:block lg:w-3/12">
            <div
              class="absolute -right-16 bottom-0"
              style="height: calc(100% - 114px); width: calc(100% + 142px);"
            >
              <div class="relative z-10 h-full overflow-hidden">
                <NuxtPicture
                  v-if="page.active_image"
                  :src="page.active_image.url"
                  :alt="page.active_image.title"
                  :img-attrs="{class: 'mask origin-top-right scale-[1.35]'}"
                />
              </div>
              <div class="mask absolute -top-4 right-1/2 z-0 size-44 -translate-y-1/4 translate-x-1/3 bg-pink" />
              <div class="mask absolute right-[30%] z-20 size-44 -translate-y-1/2 translate-x-1/2 bg-yellow" />
              <div class="top-100 mask absolute left-1/4 z-20 size-10 translate-y-full bg-violet" />
            </div>
          </div>
        </div>

        <div class="isolated relative mt-24 border-none md:mt-48 lg:hidden">
          <div class="absolute inset-x-0 bottom-0 h-1/2 bg-cream" />
          <div class="mask absolute inset-x-0 -top-16 z-0 m-auto size-32 bg-pink md:-top-32 md:size-64" />
          <NuxtPicture
            v-if="page.active_image"
            :img-attrs="{class: 'mask relative aspect-1 object-right'}"
            :src="page.active_image.url"
            :alt="page.active_image.name"
            sizes="470px"
          />
          <div class="mask absolute -bottom-28 -right-16 size-48 bg-yellow md:-right-32 md:size-96" />
          <div class="mask absolute -bottom-16 left-10 size-14 bg-violet md:left-20 md:size-28" />
        </div>
      </header>

      <section class="space-y-20 pb-16 pt-40 md:pb-12 md:pt-16 lg:pt-52">
        <div class="container">
          <BaseChipTitle
            title="Wat zeggen je"
            chip="toekomstige collega’s"
            center
          />
          <ReferenceCarousel :references="page.template.references" />
        </div>
        <EmployerCarousel />
      </section>
    </BaseSectionTile>

    <BaseSectionTile
      fg-class="bg-cream overflow-hidden"
      bg-class="bg-white pb-10 pt-16 lg:pb-24 lg:pt-20"
    >
      <VacancyFittingSection />
    </BaseSectionTile>

    <ApplicationsProcessSection class="overflow-hidden bg-cream pt-20" />

    <VacancyNearbySection class="pt-14 lg:pt-32" />

    <section class="container pt-16">
      <BaseCard
        round
        class="bg-white px-8 pt-16 lg:grid lg:grid-cols-2 lg:gap-x-20 lg:p-20"
      >
        <div class="mb-4 space-y-6">
          <BaseChipTitle
            title="Westerduin is"
            chip="menskracht"
          />
          <SofieText
            class="prose md:prose-lg lg:prose-xl"
            :value="page?.template?.about"
          />
          <div class="flex flex-col gap-6 lg:flex-row lg:justify-between">
            <NuxtLink
              to="/over-westerduin"
              class="btn btn-md btn-primary size-fit"
            >
              Onze werkwijze
            </NuxtLink>
            <IconsGreatPlaceToWork class="lg:w-26 w-14" />
          </div>
        </div>
        <NuxtPicture
          v-if="page?.template?.about_image"
          :img-attrs="{class: 'aspect-[4/3] w-full rounded-2xl object-cover lg:order-first lg:aspect-[5/6]'}"
          :src="page?.template?.about_image.url"
          alt="Werkwijze"
        />
      </BaseCard>
    </section>
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  middleware: ['sofie-page'],
  header: {
    absolute: true,
  },
});

const page = useSofiePage();
</script>

<style>
.markers ul {
  padding-left: 0;
}

.markers li {
  list-style-type: none;
  background-image: url("~/assets/img/check-square.svg");
  padding-left: 3em;
  background-repeat: no-repeat;
  line-height: 40px;
  margin: 1em 0;
}
</style>
